// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bF_kC";
export var caseStudyBackground__lineColor = "bF_kz";
export var caseStudyHead__imageWrapper = "bF_kv";
export var caseStudyProjectsSection = "bF_kD";
export var caseStudyQuote__bgLight = "bF_kK";
export var caseStudyQuote__bgRing = "bF_kx";
export var caseStudySolution__ring = "bF_kH";
export var caseStudyVideo__ring = "bF_kN";
export var caseStudy__bgDark = "bF_ks";
export var caseStudy__bgLight = "bF_kr";